.section-parent {
  display: flex;
  justify-content: center;
  padding: 100px 20px;
  text-align: center;
  background-color: white;
  color: black;
  border-bottom: 2px solid;
}

.section-content {
  display: flex;
  flex-direction: column;
  width: 75%;
  max-width: 1000px;
  gap: 15px;
}
