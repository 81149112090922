.footer {
  display: flex;
  justify-content: center;
  padding: 60px 20px;
  background-color: black;
  color: white;
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 10px;
}

.social-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  a {
    color: white;
    margin: 0 5px;
  }
}
