.hero {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 40px;
  position: relative;
  overflow: hidden;
  background-color: black;
}

.hero-content {
  max-width: 600px;
  z-index: 2;
}

.hero h1 {
  font-size: 3rem;
  font-weight: bold;
  color: white;
}

.hero p {
  font-size: 1.2rem;
  margin: 20px 0;
  line-height: 1.5;
  color: white;
}

.cta-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  background: white;
  color: black;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
}
