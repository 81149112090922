.header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rebeccapurple;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: 80px;
}

.mobile-menu {
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  height: calc(100vh - 80px);
  padding-bottom: 80px;
  background-color: rebeccapurple;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(-100%);
  transition: transform 0.5s ease-in-out;
  z-index: 999;
}

.mobile-menu.open {
  transform: translateY(0);
}

.mobile-menu a {
  color: white;
  text-decoration: none;
  margin: 15px 0;
}

@media (min-width: 769px) {
  .menu-toggle,
  .mobile-menu {
    display: none;
  }

  .desktop-nav {
    display: flex;
    gap: 20px;
  }

  .desktop-nav a {
    color: white;
    text-decoration: none;
  }
}

@media (max-width: 768px) {
  .menu-toggle {
    display: flex;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    z-index: 1001;
  }

  .desktop-nav {
    display: none;
  }
}
